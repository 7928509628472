const data = [
    { name: "Amazon Appeals"},
    { name: "​Inauthentic Item Suspensions"},
    { name: "Multiple/ Related Account Suspensions"},
    { name: "​Amazon Verification Suspensions"},
    { name: "Forged or Manipulated Documentation"},
    { name: "Defending Buyer Complaints"},
    { name: "Plan to Prevent Suspension"},
    { name: "Intellectual Property (IP) Law"},
    { name: "Reinstating ASIN"},
    { name: "Fraud or Illegal Activity"},
    { name: "Hacked Account"},
    { name: "Listing Hijackers"}
]

export default data














