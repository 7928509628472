import "./NoPage.css"

const NoPage = () => {
    return (
    <div className="page-not-found">
      <h1>404</h1>
      <h1>Page Not Found</h1>
    </div>
    )
    
    
  };
  
  export default NoPage;